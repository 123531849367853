import { useQuery } from 'react-query'
import useApi from './useApi'

const UPDATE_INTERVAL = 60000

export interface AppHealthData {
  healthChecks: {
    signups: {
      count: number
    }
    response_time?: {
      response_time: number
      up: boolean
    }
    subscriptions?: {
      count: number
      up: boolean
    }
  }
  isLoading: boolean
  isError: boolean
  lastUpdated: number
}

export const useAppHealth = (appName: string): AppHealthData => {
  const { signups, responseTime, subscriptions } = useApi()

  const {
    dataUpdatedAt: signupsUpdatedAt,
    isLoading: signupsLoading,
    data: signupsData,
    isError: signupsError,
  } = useQuery([`${appName}Signups`], () => signups(appName), {
    refetchInterval: UPDATE_INTERVAL,
    refetchOnWindowFocus: 'always',
    retry: 3,
  })

  const {
    data: responseTimeData,
    isLoading: responseTimeLoading,
    isError: responseTimeError,
  } = useQuery([`${appName}ResponseTime`], () => responseTime(appName), {
    refetchInterval: UPDATE_INTERVAL,
    refetchOnWindowFocus: 'always',
    retry: 3,
  })

  const {
    data: subscriptionsData,
    isLoading: subscriptionsLoading,
    isError: subscriptionsError,
  } = useQuery([`${appName}Subscriptions`], () => subscriptions(appName), {
    refetchInterval: UPDATE_INTERVAL,
    refetchOnWindowFocus: 'always',
    retry: 3,
  })

  return {
    healthChecks: {
      signups: {
        count: signupsData?.count || 0,
      },
      response_time: responseTimeData,
      subscriptions: subscriptionsData,
    },
    isLoading: signupsLoading || responseTimeLoading || subscriptionsLoading,
    isError: signupsError || responseTimeError || subscriptionsError,
    lastUpdated: signupsUpdatedAt,
  }
}
