import { useEffect } from 'react'
import MetricTile from './MetricTile'
import { useAppHealth } from '../hooks/useAppHealth'

interface AppHealthPageProps {
  appName: string
  displayName: string
}

export const AppHealthPage = ({ appName, displayName }: AppHealthPageProps) => {
  const { healthChecks, isLoading, lastUpdated } = useAppHealth(appName)

  const getOverallHealth = (): string => {
    if (!healthChecks) return '⚪️'
    if (healthChecks.signups.count && healthChecks.signups.count > 0)
      return '🟢'
    return '🔴'
  }

  useEffect(() => {
    document.title = `${getOverallHealth()} ${displayName} Health`
  }, [healthChecks, displayName])

  return (
    <div className="flex h-full min-h-screen w-full flex-col bg-neutral-900 font-mono">
      <div className="flex w-full flex-col justify-center self-center px-5 py-10 2xl:w-2/3">
        <h1 className="self-center text-3xl font-bold text-white">
          {displayName} Health
          <span className="animate-ping text-neutral-600">|</span>
        </h1>
        <p className="self-center text-sm text-neutral-400">
          Last Updated: {new Date(lastUpdated).toLocaleTimeString('de')}
        </p>

        <div className="mt-10 grid w-full grid-cols-1 justify-center gap-5 self-center md:grid-cols-3">
          <MetricTile
            label="Webfunnel"
            text={
              isLoading
                ? 'Loading...'
                : `Response Time: ${healthChecks.response_time?.response_time}ms`
            }
            up={healthChecks.response_time?.up ?? false}
            isLoading={isLoading}
          />

          <MetricTile
            label="Signups (1h)"
            text={
              isLoading
                ? 'Loading...'
                : `Number of Signups: ${healthChecks.signups.count}`
            }
            up={healthChecks.signups.count > 0}
            isLoading={isLoading}
          />

          <MetricTile
            label="Stripe Subscriptions (1h)"
            text={
              isLoading
                ? 'Loading...'
                : `Number of Stripe Subscriptions: ${
                    healthChecks.subscriptions?.count ?? 0
                  }`
            }
            up={healthChecks.subscriptions?.up ?? false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
