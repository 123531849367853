import { Link } from 'react-router-dom'

// Define the health check type
interface HealthCheck {
  up: boolean
}

interface HealthChecksObject {
  [key: string]: HealthCheck
}

interface AppHealthTileProps {
  name: string
  link: string
  healthChecks?: HealthChecksObject
}

export const AppHealthTile = ({
  name,
  link,
  healthChecks,
}: AppHealthTileProps) => {
  const getOverallHealth = (): string => {
    if (!healthChecks) return '⚪️'

    const total = Object.keys(healthChecks).length
    const healthy = Object.values(healthChecks).filter(
      (check) => check.up === true,
    ).length

    if (healthy === 0) return '🔴'
    if (healthy === total) return '🟢'
    return '🟡'
  }

  const getStatusColor = (): string => {
    if (!healthChecks) return 'text-neutral-400'

    const total = Object.keys(healthChecks).length
    const healthy = Object.values(healthChecks).filter(
      (check) => check.up === true,
    ).length

    if (healthy === 0) return 'text-red-500'
    if (healthy === total) return 'text-green-500'
    return 'text-yellow-500'
  }

  const getHealthCheckCount = (): string => {
    if (!healthChecks) return '0/0'
    const total = Object.keys(healthChecks).length
    const healthy = Object.values(healthChecks).filter(
      (check) => check.up === true,
    ).length
    return `${healthy}/${total}`
  }

  return (
    <Link
      to={link}
      className="relative flex h-40 w-full flex-col justify-center border border-neutral-600 font-mono transition-colors hover:border-neutral-400">
      <div className="flex flex-col items-center self-center text-center">
        <h2 className="mb-2 text-xl font-bold text-white">
          {name} {getOverallHealth()}
        </h2>
        <p className={`text-sm ${getStatusColor()}`}>
          <span className="font-bold">{getHealthCheckCount()}</span> Health
          Checks Passed
        </p>
      </div>
    </Link>
  )
}
