interface MetricTileProps {
  label: string
  text: string
  up: boolean
  isLoading: boolean
  error?: unknown
}

const upColor = (up: boolean, isLoading: boolean, error: unknown) => {
  if (isLoading) return 'text-neutral-400'
  if (error) return 'text-red-400'
  return up ? 'text-green-400' : 'text-red-400'
}

export const MetricTile = ({
  label,
  text,
  up,
  isLoading,
  error,
}: MetricTileProps) => (
  <div
    className={`relative flex h-52 w-full flex-col justify-center border ${
      error ? 'border-red-600' : 'border-neutral-600'
    }`}>
    <p
      className={`self-center text-xl font-bold ${upColor(
        up,
        isLoading,
        error,
      )}`}>
      {isLoading ? 'Loading...' : `${label} ${error ? '❌' : up ? '🟢' : '🔴'}`}
    </p>
    <p className="self-center text-sm text-neutral-400">
      {error ? 'Error fetching data' : text}
    </p>
  </div>
)

export default MetricTile
