import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from 'react-router-dom'
import { AsanaRebel } from './components/AsanaRebel'
import { Home } from './components/Home'
import PerfectDay from './components/PerfectDay'
import VisualMind from './components/VisualMind'
import ChatAIPlus from './components/ChatAIPlus'
import Filterly from './components/Filterly'
import { IPProtection } from './components/IPProtection'

// Create a NavLink component to handle active state
const NavLink = ({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) => {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link
      to={to}
      className={`text-neutral-300 transition-all hover:text-white
        ${isActive ? 'font-bold underline underline-offset-4' : ''}`}>
      {children}
    </Link>
  )
}

export default function App() {
  return (
    <IPProtection>
      <BrowserRouter>
        <div className="min-h-screen bg-neutral-900 font-mono">
          <nav className="sticky top-0 z-50 border-b border-neutral-700 bg-neutral-800/95 p-4 backdrop-blur-sm">
            <ul className="flex justify-center gap-8">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/asana-rebel">Asana Rebel</NavLink>
              </li>
              <li>
                <NavLink to="/perfect-day">Perfect Day</NavLink>
              </li>
              <li>
                <NavLink to="/visual-mind">Visual Mind</NavLink>
              </li>
              <li>
                <NavLink to="/filterly">Filterly</NavLink>
              </li>
              <li>
                <NavLink to="/chatai-plus">ChatAI Plus</NavLink>
              </li>
            </ul>
          </nav>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/asana-rebel" element={<AsanaRebel />} />
            <Route path="/perfect-day" element={<PerfectDay />} />
            <Route path="/visual-mind" element={<VisualMind />} />
            <Route path="/chatai-plus" element={<ChatAIPlus />} />
            <Route path="/filterly" element={<Filterly />} />
          </Routes>
        </div>
      </BrowserRouter>
    </IPProtection>
  )
}
