import { ReactNode, useEffect, useState } from 'react'

interface IPProtectionProps {
  children: ReactNode
}

export function IPProtection({ children }: IPProtectionProps) {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null)

  useEffect(() => {
    async function checkIP() {
      // Skip IP check if validation is disabled
      if (process.env.REACT_APP_IP_VALIDATION !== 'true') {
        setIsAllowed(true)
        return
      }

      try {
        // Get client's IP using a free IP service
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        const clientIP = data.ip

        // Get allowed IPs from environment variable
        const allowedIPs = process.env.REACT_APP_ALLOWED_IPS?.split(',') || []

        setIsAllowed(allowedIPs.includes(clientIP))
      } catch (error) {
        console.error('Error checking IP:', error)
        setIsAllowed(false)
      }
    }

    checkIP()
  }, [])

  if (isAllowed === null) {
    return (
      <div className="flex h-screen items-center justify-center">
        Loading...
      </div>
    )
  }

  if (!isAllowed) {
    return (
      <div className="flex h-screen items-center justify-center bg-black">
        <div className="text-center text-white">
          <h1 className="text-2xl font-bold">Access Denied</h1>
          <p className="mt-2">Please use AR VPN to access this app.</p>
        </div>
      </div>
    )
  }

  return <>{children}</>
}
