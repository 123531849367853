interface HealthChecksResponse {
  message: string
  health_checks: HealthChecksObject
}

export interface HealthChecksObject {
  [key: string]: HealthChecksEntity
  today_tab: HealthChecksEntity
  webfunnel: HealthChecksEntity
  sidekiq: HealthChecksEntity
  purchases_web: HealthChecksEntity
  ab_testing_system: HealthChecksEntity
}

interface HealthChecksEntity {
  up: boolean
  response_time?: number
  job_count?: number
  purchases_count?: number
  proceeds?: string
  signups_count?: number
}

interface ResponseTimeResponse {
  response_time: number
  up: boolean
}

interface SignupsResponse {
  up: boolean
  count: number
}

interface SubscriptionsResponse {
  up: boolean
  count: number
}

const AR_API_BASE_URL = process.env.REACT_APP_ASANA_REBEL_API_URL!
const NEXUS_API_BASE_URL = process.env.REACT_APP_NEXUS_SUPABASE_URL!
const NEXUS_API_KEY = process.env.REACT_APP_NEXUS_ANON_KEY!

export default function useApi() {
  const requestQueryString = async (
    url: string,
    method: string,
    path: string,
    data: Record<string, string> = {},
    authToken = '',
  ) => {
    const params = new URLSearchParams(data).toString()
    const res = await fetch(`${url}${path}?${params}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
    })

    return res.json()
  }

  const responseTime = (appName: string): Promise<ResponseTimeResponse> =>
    requestQueryString(
      NEXUS_API_BASE_URL,
      'GET',
      '/functions/v1/response-time',
      { app_name: appName },
      NEXUS_API_KEY,
    )

  const signups = (appName: string): Promise<SignupsResponse> =>
    requestQueryString(
      NEXUS_API_BASE_URL,
      'GET',
      '/functions/v1/signups',
      { app_name: appName },
      NEXUS_API_KEY,
    )

  const getHealthChecks = (): Promise<HealthChecksResponse> =>
    requestQueryString(AR_API_BASE_URL, 'GET', '/api/v1/health_checks')

  return {
    getHealthChecks,
    signups,
    responseTime,
    subscriptions: (appName: string): Promise<SubscriptionsResponse> =>
      requestQueryString(
        NEXUS_API_BASE_URL,
        'GET',
        '/functions/v1/subscriptions',
        { app_name: appName },
        NEXUS_API_KEY,
      ),
  }
}
