import { useEffect } from 'react'
import { useQuery } from 'react-query'
import useApi from '../hooks/useApi'
import { AppHealthTile } from './AppHealthTile'
import { useAppHealth } from '../hooks/useAppHealth'

export const Home = () => {
  const { getHealthChecks } = useApi()
  const { data: arData } = useQuery(['arHealthChecks'], getHealthChecks)

  const pdHealth = useAppHealth('perfectday')
  const vmHealth = useAppHealth('visualmind')
  const filterlyHealth = useAppHealth('filterly')
  const chatAiHealth = useAppHealth('chataiplus')

  useEffect(() => {
    document.title = `AR App Health Dashboard`
  }, [])

  return (
    <div className="container mx-auto px-5 py-10 font-mono">
      <h1 className="mb-10 text-center text-4xl font-bold text-white">
        App Health Dashboard
      </h1>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <AppHealthTile
          name="Asana Rebel"
          link="/asana-rebel"
          healthChecks={arData?.health_checks}
        />
        <AppHealthTile
          name="Perfect Day"
          link="/perfect-day"
          healthChecks={{
            signups: { up: pdHealth.healthChecks.signups.count > 0 },
            response_time: pdHealth.healthChecks.response_time || { up: false },
            subscriptions: pdHealth.healthChecks.subscriptions || { up: false },
          }}
        />
        <AppHealthTile
          name="Visual Mind"
          link="/visual-mind"
          healthChecks={{
            signups: { up: vmHealth.healthChecks.signups.count > 0 },
            response_time: vmHealth.healthChecks.response_time || { up: false },
            subscriptions: vmHealth.healthChecks.subscriptions || { up: false },
          }}
        />
        <AppHealthTile
          name="Filterly"
          link="/filterly"
          healthChecks={{
            signups: { up: filterlyHealth.healthChecks.signups.count > 0 },
            response_time: filterlyHealth.healthChecks.response_time || {
              up: false,
            },
            subscriptions: filterlyHealth.healthChecks.subscriptions || {
              up: false,
            },
          }}
        />
        <AppHealthTile
          name="ChatAI Plus"
          link="/chatai-plus"
          healthChecks={{
            signups: { up: chatAiHealth.healthChecks.signups.count > 0 },
            response_time: chatAiHealth.healthChecks.response_time || {
              up: false,
            },
            subscriptions: chatAiHealth.healthChecks.subscriptions || {
              up: false,
            },
          }}
        />
      </div>
    </div>
  )
}
